<template>
  <div class="product-auction padding-bottom">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-12 mt-2">
          <div class="row mb-2">
            <div class="col-12 col-lg-6">
              <h2 class="title mb-5 mb-lg-0 title-lotes-disponiveis">
                Lotes Disponíveis
              </h2>
            </div>
            <div
              class="col-12 col-lg-6 d-flex justify-content-center align-items-start flex-wrap flex-lg-nowrap"
            >
              <div
                class="combo mr-lg-4 mb-4"
                @click="classificacaoShow = !classificacaoShow"
                @focusout="handleFocusOut"
                tabindex="0"
              >
                <div class="select-numero-lote">
                  {{ classificacaoText }}
                </div>
                <ul :class="{ active: classificacaoShow }">
                  <li
                    @click="classificar('')"
                    :class="{ active: classificacao == '' }"
                  >
                    <input
                      type="radio"
                      name="classificacao"
                      class="form-filtrar"
                      value=""
                      v-model="classificacao"
                    />Número do Lote
                  </li>
                  <li
                    v-for="item in getOrdens"
                    :key="item.titulo"
                    @click="classificar(item.ordem)"
                    :class="{ active: classificacao == item.ordem }"
                  >
                    <input
                      type="radio"
                      name="classificacao"
                      class="form-filtrar"
                      :value="item.ordem"
                      v-model="classificacao"
                    />
                    {{ item.descricao }}
                  </li>
                </ul>
              </div>
              <div
                class="combo"
                v-if="getCategoria"
                @click="filtrarShow = !filtrarShow"
                @focusout="handleFocusOut"
                tabindex="0"
              >
                <div class="select-numero-lote select-filtrar">
                  FILTRAR
                  <span
                    class="badge badge-warning ml-2 p-1"
                    v-if="qtdFiltro > 0"
                    >{{ qtdFiltro }}</span
                  >
                </div>
                <ul :class="{ active: filtrarShow }" class="ul-filtrar">
                  <li
                    v-for="sub in getCategoria.subcategorias"
                    :key="sub.categoriaId"
                    @click="filtrarCategoria(sub.categoriaId)"
                    :class="{ active: categoria == sub.categoriaId }"
                  >
                    <input
                      type="radio"
                      name="filtrar"
                      class="form-filtrar"
                      :value="sub.categoriaId"
                      v-model="categoria"
                    />
                    {{ sub.descricao }}
                  </li>
                  <li @click="filtrarEncerrados()" class="item-encerrados">
                    <input
                      type="checkbox"
                      name="encerrados"
                      class="form-filtrar"
                      v-model="removerEncerrados"
                    />
                    Remover Encerrados
                  </li>
                  <li
                    class="text-center text-danger mt-3"
                    @click="limparFiltro()"
                  >
                    LIMPAR
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" v-if="!getLotesLoading">
            <div class="col-12 text-right mb-3">
              <p class="m-0">{{ getTotalLotes }} Lotes Encontrados</p>
            </div>
          </div>
          <div class="row mb-30-none justify-content-center">
            <lote-card-vertical
              v-for="lote in getLotesPorLeilao"
              :key="lote.id"
              :lote="lote"
              classeRaiz="col-lg-6 col-md-12 col-sm-12 justify-content-center"
            />
            <div
              class="col-12 text-center m-5"
              v-if="
                !getLotesLoading &&
                getLotesPorLeilao &&
                getLotesPorLeilao.length == 0
              "
            >
              <h6 class="text-muted">
                NENHUM LOTE ENCONTRADO COM O FILTRO SELECIONADO
              </h6>
            </div>
            <h4 class="title mb-5 mt-5 verTodos" v-if="getLotesLoading">
              CARREGANDO...
            </h4>
          </div>
          <div class="center d-flex" v-if="getIsNextPageLote">
            <h4
              class="title mb-5 mt-5 verTodos"
              @click="carregarMaisLeiloes"
              v-if="!getLotesLoading"
            >
              CARREGAR MAIS LOTES
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoteCardVertical from '@/components/LoteCardVertical.vue';
// import LoteCardLoader from '@/components/LoteCardLoader.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  props: {
    leilaoId: String,
  },
  data() {
    return {
      classificacaoShow: false,
      classificacaoText: 'CLASSIFICAR POR',
      filtrarShow: false,
      filtro: '',
      classificacao: '',
      removerEncerrados: false,
      categoria: 0,
      categorias: [],
      qtdFiltro: 0,
      pageNumber: 0,
    };
  },
  components: {
    LoteCardVertical,
    // LoteCardLoader,
  },
  watch: {
    pageNumber(value, oldvalue) {
      if (value == oldvalue) return;

      this.getPagina(this.pageNumber);
    },
  },
  computed: {
    ...mapGetters([
      'getOrdens',
      'getLotesPorLeilao',
      'getLotesLoading',
      'getCategorias',
      'getLeilao',
      'getIsNextPageLote',
      'getTotalLotes',
    ]),
    getCategoria() {
      const leilao = this.getLeilao;
      return this.getCategorias.find((x) => x.descricao == leilao.categoria);
    },
  },
  methods: {
    ...mapActions(['setLotesPorLeilao']),
    ...mapMutations(['SET_LOTES_POR_LEILAO', 'IS_NEXT_PAGE_LOTE']),
    carregarMaisLeiloes() {
      this.pageNumber++;
    },
    filtrarLotes() {
      this.SET_LOTES_POR_LEILAO();
      this.filtro = {
        ordem: this.classificacao,
        categorias: this.categorias,
        removerEncerrados: this.removerEncerrados,
      };
      this.atualizaLotes({
        pageNumber: 1,
        pageSize: 6,
        categorias: this.filtro.categorias,
        ordem: this.filtro.ordem || '',
        removerEncerrados: this.filtro.removerEncerrados || false,
      });
    },
    getPagina(pageNumber) {
      this.atualizaLotes({
        pageNumber: pageNumber,
        pageSize: 6,
        categorias: this.filtro.categorias,
        ordem: this.filtro.ordem || '',
        removerEncerrados: this.filtro.removerEncerrados || false,
      });
    },
    classificar(ordem) {
      this.classificacao = ordem;
      this.filtrarLotes();
    },
    filtrarCategoria(categoriaId) {
      if (this.categoria == 0) this.qtdFiltro++;
      this.categoria = categoriaId;
      this.categorias = [categoriaId];
      this.filtrarLotes();
    },
    filtrarEncerrados() {
      this.removerEncerrados = !this.removerEncerrados;
      this.removerEncerrados ? this.qtdFiltro++ : this.qtdFiltro--;
      this.filtrarLotes();
    },
    limparFiltro() {
      this.qtdFiltro = 0;
      this.categoria = 0;
      this.categorias = [];
      this.removerEncerrados = false;
      this.filtrarLotes();
    },
    handleFocusOut() {
      this.filtrarShow = false;
      this.classificacaoShow = false;
    },
    atualizaLotes(payload) {
      this.setLotesPorLeilao({
        leilaoId: this.leilaoId,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize || 6,
        categorias: payload.categorias || '',
        ordem: payload.ordem || '',
        removerEncerrados: payload.removerEncerrados || false,
      });
    },
  },
  async created() {
    this.pageNumber++;
  },
  destroyed() {
    this.SET_LOTES_POR_LEILAO();
    this.IS_NEXT_PAGE_LOTE();
  },
};
</script>

<style scoped>
button {
  border-radius: 13px;
}
.combo {
  position: relative;
  width: 100%;
  height: 35px;
}
.select-numero-lote {
  font-weight: 800;
  color: #52606d;
  border-radius: 25px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  height: 35px;
  border: none;
  background: #f7f7f7;
  background-image: url(/assets/images/chevrons-down.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 6px;
  width: 100%;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 9;
  font-size: 0.9em;
}

.combo ul {
  position: absolute;
  top: 0;
  padding: 45px 0 10px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 8;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  text-align: left;
  display: none;
}

.combo ul.active {
  display: block;
  -webkit-animation: fadeIn 500ms;
  animation: fadeIn 500ms;
}

.combo ul li {
  color: #52606d;
  padding: 2px 15px;
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.combo ul li:hover,
.combo ul li.active {
  background-color: #d9d9d973;
  cursor: pointer;
}

.combo label {
  margin: 0;
  padding: 0;
}

.select-filtrar {
  z-index: 7 !important;
}

.ul-filtrar {
  z-index: 6 !important;
}

.item-encerrados {
  margin-top: 20px;
  padding: 15px 0 15px 15px !important;
  border-top: 1px solid #ddd;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.verTodos {
  color: #0b184a;
  cursor: pointer;
}

.form-filtrar {
  display: inline;
  width: 12px;
  height: 12px;
  margin-right: 15px;
}
@media (max-width: 1199px) {
  .title-lotes-disponiveis {
    text-align: center;
  }
}
</style>
