<template>
  <section>
    <div class="row justify-content-center">
      <leilao-card
        v-for="leilao in getLeiloesFechado"
        :key="leilao.id"
        classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3"
        :leilao="leilao"
      />
      <div
        class="col-12 text-center my-5"
        v-if="
          !getLeiloesFechadoLoading &&
          getLeiloesFechado &&
          getLeiloesFechado.length == 0
        "
      >
        <h6 class="text-muted">NÃO HÁ LEILÕES ENCERRADOS</h6>
      </div>
      <h4
        class="title mb-5 mt-5 verTodos text-center"
        v-if="getLeiloesFechadoLoading"
      >
        CARREGANDO...
      </h4>
    </div>
    <div class="center d-flex" v-if="getIsNextPage">
      <h4
        class="title mb-5 mt-5 verTodos"
        @click="carregarMaisLeiloes"
        v-if="!getLeiloesFechadoLoading"
      >
        CARREGAR MAIS LEILOES
      </h4>
    </div>
  </section>
</template>

<script>
import LeilaoCard from '@/components/LeilaoCard.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    LeilaoCard,
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  watch: {
    pageNumber(value, oldvalue) {
      if (value == oldvalue) return;

      this.setLeiloesPorStatus({
        pageSize: 4,
        pageNumber: this.pageNumber,
        status: 'fechado',
      });
    },
  },
  computed: {
    ...mapGetters([
      'getLeiloesFechado',
      'getLeiloesFechadoLoading',
      'getIsNextPage',
    ]),
  },
  methods: {
    ...mapActions(['setLeiloesPorStatus']),
    ...mapMutations(['SET_LEILOES_FECHADO', 'IS_NEXT_PAGE']),

    carregarMaisLeiloes() {
      this.pageNumber++;
    },
  },
  async created() {
    this.pageNumber++;
  },
  destroyed() {
    this.SET_LEILOES_FECHADO();
    this.IS_NEXT_PAGE();
  },
};
</script>
