<template>
  <header>
    <div class="header-bottom">
      <div class="container">
        <div class="header-wrapper">
          <div class="logo">
            <router-link to="/"> <img :src="logo" :alt="siteName" :title="siteName" /></router-link>
          </div>
          <ul class="menu">
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <template v-for="pagina in getPaginas">
              <li :key="pagina.titulo" v-if="pagina.menu">
                <a :href="'/pagina/' + pagina.rota">
                  {{ pagina.titulo }}
                </a>
              </li>
            </template>
            <li>
              <router-link to="/validar">Validação</router-link>
            </li>
            <li>
              <router-link to="/cadastro">Cadastre-se</router-link>
            </li>
          </ul>
          <div class="header-top-wrapper">
            <div class="header-bar d-lg-none">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul class="cart-button-area">
              <li>
                <a v-if="getAuth === null" href="#" @click="goToLogin(getAuth)">
                  <span class="pr-2">Olá, Visitante</span>
                  <img :src="require('@/assets/images/dashboard/default_user.png')" alt="" class="rounded-circle user-button" width="40px" />
                </a>
                <router-link v-else to="/dashboard/">
                  <span class="pr-2 text-truncate">Olá, {{ getNomeUsuarioLogado }}</span>
                  <img :src="getFotoUsuarioLogado" alt="" class="rounded-circle user-button" width="40px" v-if="getFotoUsuarioLogado" />
                  <img v-else :src="require('@/assets/images/dashboard/default_user.png')" alt="" class="rounded-circle user-button" width="40px" />
                </router-link>
              </li>

              <li v-if="getAuth || getFotoUsuarioLogado">
                <a href="javascript:void(0)" @click="logout">
                  <span class="pr-2">Sair</span>
                  <i class="fa fa-sign-out-alt"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      titulo: '',
      userCompleted: '',
      logo: process.env.VUE_APP_LOGO_PATH,
      siteName: process.env.VUE_APP_SITE_NAME,
    };
  },
  computed: {
    ...mapGetters(['getAuth', 'getNomeUsuarioLogado', 'getFotoUsuarioLogado', 'getPaginas']),
  },
  methods: {
    ...mapActions(['logout', 'pesquisaLotesPorNome', 'checkRegisterCompleted']),
    async pesquisa() {
      await this.pesquisaLotesPorNome({
        pageSize: 8,
        pageNumber: 1,
        Titulo: this.titulo,
      });
      document.getElementById('lotesdestaque').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    },
    goToLogin(getAuth) {
      if (getAuth === null) {
        this.$router.push({
          path: '/login',
          query: { returnUrl: this.$route.path },
        });
      }
      // if (this.userCompleted == false && getAuth != null) {
      //   this.$router.push({ name: "cadastro" });
      // }
    },
  },
  async mounted() {
    await this.checkRegisterCompleted();
  },
};
</script>
<style scoped>
.text-color {
  color: black;
}

ul li a {
  color: black;
}

.search-form input {
  height: 40px;
  text-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: black;
  padding: 0 45px 0 20px;
}

.header-bar span {
  background: black;
}

@media (max-width: 576px) {
  .description-login {
    display: none;
    visibility: collapse;
  }
  .header-wrapper {
    align-items: center;
    justify-content: center;
  }
  .header-top-wrapper {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 15px;
  }
}
</style>
