<template>
  <div id="app">
    <scroll-top-button />
    <heade v-if="$route.name != 'Fatura'" />
    <router-view :key="$route.fullPath"></router-view>
    <foote v-if="$route.name != 'Fatura'" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ScrollTopButton from '@/components/ScrollTopButton.vue';
export default {
  components: {
    heade: Header,
    foote: Footer,
    ScrollTopButton,
  },
};
</script>
<style>
.swal2-popup.swal2-toast .swal2-title {
  font-size: 14px !important;
}

.swal2-popup.swal2-modal .swal2-icon-warning .swal2-show {
  font-size: 10px !important;
}

body > div.swal2-container.swal2-center.swal2-backdrop-show > div {
  font-size: 12px !important;
}

body
  > div.swal2-container.swal2-center.swal2-backdrop-show
  > div
  > div.swal2-actions {
  flex-wrap: nowrap;
}
#reader-qrcode {
  min-width: 300px;
  width: 60%;
}
</style>
