import http from '../../service/http/http';

const state = {
  lotesMaisVisualizados: [],
  lotesPorLeilao: [],
  lotesLoading: false,
  lotesMaisVisualizadosLoading: false,
  lote: '',
  loteInfo: '',
  loteInfoLoading: '',
  loteIdSelecionado: '',
  lotesArrematados: [],
  loteVerificacaoLoading: false,
  loteVerificacaoErro: '',
  loteHabilitado: '',
  loteHabilitadoErro: '',
  boletoLoading: false,
  boletoErro: '',
  numerosLotes: [],
  isNextPage: '',
  totalLotes: 0,
};

const mutations = {
  SET_LOTES_MAIS_VISUALIZADOS: (state, value) =>
    value
      ? (state.lotesMaisVisualizados = value)
      : (state.lotesMaisVisualizados = []),
  SET_LOTES_POR_LEILAO: (state, value) =>
    value ? state.lotesPorLeilao.push(...value) : (state.lotesPorLeilao = []),
  SET_LOTES_LOADING: (state, value) =>
    value ? (state.lotesLoading = value) : (state.lotesLoading = false),
  SET_LOTES_MAIS_VISUALIZADOS_LOADING: (state, value) =>
    value
      ? (state.lotesMaisVisualizadosLoading = value)
      : (state.lotesMaisVisualizadosLoading = false),
  SET_LOTE: (state, value) =>
    value ? (state.lote = value) : (state.lote = ''),
  SET_LOTE_INFO: (state, value) =>
    value ? (state.loteInfo = value) : (state.loteInfo = ''),
  SET_LOTE_INFO_LOADING: (state, value) =>
    value ? (state.loteInfoLoading = value) : (state.loteInfoLoading = false),
  SET_LOTEID_SELECIONADO: (state, value) =>
    value ? (state.loteIdSelecionado = value) : (state.loteIdSelecionado = ''),
  SET_LOTES_ARREMATADOS: (state, value) =>
    value ? (state.lotesArrematados = value) : (state.lotesArrematados = []),
  SET_LOTES_ARREMATADOS_LOADING: (state, value) =>
    value
      ? (state.lotesMaisVisualizadosLoading = value)
      : (state.lotesMaisVisualizadosLoading = false),
  SET_LOTE_VERIFICACAO_LOADING: (state, value) =>
    value
      ? (state.loteVerificacaoLoading = value)
      : (state.loteVerificacaoLoading = false),
  SET_LOTE_VERIFICACAO_ERRO: (state, value) =>
    value
      ? (state.loteVerificacaoErro = value)
      : (state.loteVerificacaoErro = ''),
  SET_LOTE_HABILITADO: (state, value) =>
    value ? (state.loteHabilitado = value) : (state.loteHabilitado = ''),
  SET_LOTE_HABILITADO_ERRO: (state, value) =>
    value
      ? (state.loteHabilitadoErro = value)
      : (state.loteHabilitadoErro = ''),
  SET_BOLETO_LOADING: (state, value) =>
    value ? (state.boletoLoading = value) : (state.boletoLoading = false),
  SET_BOLETO_ERRO: (state, value) =>
    value ? (state.boletoErro = value) : (state.boletoErro = ''),
  SET_NUMEROS_LOTES: (state, value) =>
    value ? (state.numerosLotes = value) : (state.numerosLotes = []),
  IS_NEXT_PAGE_LOTE: (state, value) =>
    value ? (state.isNextPage = value) : (state.isNextPage = ''),
  TOTAL_LOTES: (state, value) =>
    value ? (state.totalLotes = value) : (state.totalLotes = 0),
};

const getters = {
  getLotesMaisVisualizados: (state) => state.lotesMaisVisualizados,
  getLotesPorLeilao: (state) => state.lotesPorLeilao,
  getLotesLoading: (state) => state.lotesLoading,
  getLotesMaisVisualizadosLoading: (state) =>
    state.lotesMaisVisualizadosLoading,
  getLote: (state) => state.lote,
  getLoteInfo: (state) => state.loteInfo,
  getLoteInfoLoading: (state) => state.loteInfoLoading,
  getLoteIdSelecionado: (state) => state.loteIdSelecionado,
  getlotesArrematados: (state) => state.lotesArrematados,
  getLoteVerificacaoLoading: (state) => state.loteVerificacaoLoading,
  getLoteVerificacaoErro: (state) => state.loteVerificacaoErro,
  getLoteHabilitado: (state) => state.loteHabilitado,
  getLoteHabilitadoErro: (state) => state.loteHabilitadoErro,
  getBoletoLoading: (state) => state.boletoLoading,
  getBoletoErro: (state) => state.boletoErro,
  getNumerosLotes: (state) => state.numerosLotes,
  getIsNextPageLote: (state) => state.isNextPage,
  getTotalLotes: (state) => state.totalLotes,
};

const actions = {
  // setLace({ commit }, value) {

  // },
  async setLoteInfo({ commit }, value) {
    commit('SET_LOTE_INFO_LOADING', true);
    return http({
      url: `Lote/${value}/info`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_LOTE_INFO', resp.data.data);
        commit('SET_LOTE_INFO_LOADING');
      })
      .catch((error) => {
        commit('SET_LOTE_INFO_LOADING');
        console.error(error);
      });
  },

  async setNumerosLotes({ commit }, leilaoId) {
    commit('SET_NUMEROS_LOTES');
    return http({
      url: `Lote/Numeros?leilaoId=${leilaoId}`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_NUMEROS_LOTES', resp.data.data);
      })
      .catch((error) => {
        commit('SET_NUMEROS_LOTES');
        console.error(error);
      });
  },

  async setLote({ commit }, value) {
    commit('SET_LOTES_LOADING', true);
    return http({
      url: `Lote/${value}`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_LOTE', resp.data.data);
        commit('SET_LOTES_LOADING');
        return resp.data.data;
      })
      .catch(() => commit('SET_LOTES_LOADING'));
  },
  async pesquisaLotesPorNome({ commit }, value) {
    commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING', true);
    if (!value) {
      value = {
        pageSize: 8,
        pageNumber: 1,
      };
    }
    // lote/destaques
    return http({
      url: `lote`,
      method: 'get',
      params: value,
    })
      .then((resp) => {
        commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING');
        commit('SET_LOTES_MAIS_VISUALIZADOS', resp.data);
      })
      .catch(() => {
        commit('SET_LOTES_MAIS_VISUALIZADOS');
        commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING');
      });
  },
  async setLotesPorLeilao({ commit }, value) {
    commit('SET_LOTES_LOADING', true);

    ////console.log(value);
    let filtros = {};

    if (value.categorias) {
      filtros['Categorias'] = value.categorias.toString();
    }
    if (value.precoInicial) {
      filtros['PrecoInicial'] = value.precoInicial;
    }
    if (value.precoFinal) {
      filtros['PrecoFinal'] = value.precoFinal;
    }
    if (value.ordem) {
      filtros['Ordem'] = value.ordem;
    }
    if (value.termo) {
      filtros['Termo'] = value.termo;
    }
    if (value.numeroLote) {
      filtros['NumeroLote'] = value.numeroLote;
    }
    if (value.status) {
      filtros['Status'] = value.status;
    }

    return http({
      url: 'lote',
      method: 'get',
      params: {
        LeilaoId: value.leilaoId,
        PageSize: value.pageSize,
        PageNumber: value.pageNumber,
        RemoverEncerrados: value.removerEncerrados,
        ...filtros,
      },
    })
      .then((resp) => {
        commit('SET_LOTES_POR_LEILAO', resp.data?.data);
        commit('IS_NEXT_PAGE_LOTE', resp.data.nextPage);
        commit('TOTAL_LOTES', resp.data.totalRecords);
        commit('SET_LOTES_LOADING');
      })
      .catch(() => {
        commit('SET_LOTES_POR_LEILAO');
        commit('SET_LOTES_LOADING');
      });
  },
  async setLotesMaisVisualizados({ commit }, value) {
    commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING', true);

    if (!value) {
      value = {
        leilaoId: 1,
        pageSize: 4,
        pageNumber: 1,
      };
    }
    // lote/destaques
    return new Promise((resolve, reject) => {
      http({
        url: `lote/destaques`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING');
          commit('SET_LOTES_MAIS_VISUALIZADOS', resp.data);
          resolve();
        })
        .catch(() => {
          commit('SET_LOTES_MAIS_VISUALIZADOS');
          commit('SET_LOTES_MAIS_VISUALIZADOS_LOADING');
          reject();
        });
    });
  },
  async setlotesArrematados({ commit }, value) {
    let filtros = {};

    if (value.ordem) {
      filtros['Ordem'] = value.ordem;
    }

    if (value.titulo) {
      filtros['Titulo'] = value.titulo;
    }

    commit('SET_LOTES_LOADING', true);
    return http({
      url: `usuario/lotes-arrematados`,
      method: 'get',
      params: {
        PageSize: value.pageSize,
        PageNumber: value.pageNumber,
        ...filtros,
      },
    })
      .then((resp) => {
        commit('SET_LOTES_LOADING');
        commit('SET_LOTES_ARREMATADOS', resp.data);
      })
      .catch(() => {
        commit('SET_LOTES_ARREMATADOS');
        commit('SET_LOTES_LOADING');
      });
  },
  async addPageView(_, value) {
    return http({
      url: `lote/${value}/PageView`,
      method: 'get',
    });
  },

  async solicitaAcessoLote({ commit }, payload) {
    commit('SET_LOTE_VERIFICACAO_LOADING', true);

    //console.log(payload);
    return http({
      url: `Leilao/${payload.leilaoId}/solicitar-acesso`,
      method: 'POST',
      data: payload,
    })
      .then(() => {
        //console.log(resp);
        commit('SET_LOTE_VERIFICACAO_LOADING');
        commit('SET_LOTE_VERIFICACAO_ERRO');
      })
      .catch((err) => {
        //console.log(err);
        commit('SET_LOTE_VERIFICACAO_LOADING');
        commit('SET_LOTE_VERIFICACAO_ERRO', err.response.data);
      });
  },

  async verificarAcessoLeilao({ commit }, value) {
    commit('SET_LOTE_VERIFICACAO_LOADING', true);
    return http({
      url: `Leilao/${value.leilaoId}/verificar-acesso?tipoLoteId=${value.tipoLoteId}`,
      method: 'get',
    })
      .then((resp) => {
        commit('SET_LOTE_HABILITADO', resp.data.data);
        commit('SET_LOTE_VERIFICACAO_LOADING');
        commit('SET_LOTE_HABILITADO_ERRO');
      })
      .catch((err) => {
        commit('SET_LOTE_VERIFICACAO_LOADING');
        commit('SET_LOTE_HABILITADO_ERRO', err.response.data);
      });
  },
  getBoleto({ commit }, value) {
    commit('SET_BOLETO_LOADING', true);
    return http({
      url: `fatura/gerarboleto?loteId=${value}`,
      method: 'post',
    })
      .then(() => {
        commit('SET_BOLETO_LOADING');
        return true;
      })
      .catch((err) => {
        commit('SET_BOLETO_LOADING');
        commit('SET_BOLETO_ERRO', err.response.data.message);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
