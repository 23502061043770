export let API_URL;
export let ID_URL;
export let APP_URL;
export let DOWNLOAD_BOLETO_URL;
export let PAINEL_URL;
export let ORIGIN;

if (process.env.NODE_ENV != 'development') {
  API_URL = 'https://api.fabianoayupp.eblonline.com.br/';
  ID_URL = 'https://id.fabianoayupp.eblonline.com.br/';
  APP_URL = 'https://fabianoayupp.eblonline.com.br/';
  DOWNLOAD_BOLETO_URL = 'https://api.fabianoayupp.eblonline.com.br/';
  PAINEL_URL = 'https://painel.fabianoayupp.eblonline.com.br';
  ORIGIN = 'https://fabianoayupp.eblonline.com.br';
} else {
  API_URL = 'https://localhost:4001/';
  ID_URL = 'https://localhost:5001/';
  APP_URL = 'http://localhost:8080/';
  DOWNLOAD_BOLETO_URL = 'https://localhost:4001/';
  PAINEL_URL = 'http://localhost:8081';
  ORIGIN = 'http://localhost:8081';
}
