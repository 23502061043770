<template>
  <div>
    <section class="dashboard-section padding-bottom padding-top pos-rel">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-10 col-md-7 col-lg-4 no-print">
            <div class="dashboard-widget mb-30 mb-lg-0 sticky-menu">
              <div class="user">
                <div class="thumb-area">
                  <div class="thumb">
                    <img
                      :src="getFotoUsuarioLogado"
                      alt=""
                      class="rounded-circle user-button"
                      width="40px"
                      v-if="getFotoUsuarioLogado"
                    />
                    <img
                      :src="
                        require('@/assets/images/dashboard/default_user.png')
                      "
                      alt="user"
                      v-else
                    />
                  </div>
                </div>
                <div class="content">
                  <h5 class="title">
                    <a href="javascript:void(0)">{{
                      getAuth.profile
                        ? getAuth.profile.full_name
                        : getAuth.full_name
                    }}</a>
                  </h5>
                  <span class="username">{{
                    getAuth.profile ? getAuth.profile.email : getAuth.email
                  }}</span>
                </div>
              </div>
              <ul class="dashboard-menu">
                <li
                  v-for="item in itens"
                  :key="item.nome"
                  @click="setAtivo(item.link)"
                >
                  <router-link
                    :to="item.link"
                    :class="item.ativo ? 'active' : ''"
                    ><i :class="item.icone" style="color: #0783ae"></i>
                    {{ item.nome }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  data() {
    return {
      itens: [
        {
          nome: 'Perfil',
          link: 'perfil',
          ativo: this.ativo == 'perfil',
          icone: 'flaticon-settings',
        },

        // {
        //   nome: "Meus lances",
        //   link: "#",
        //   ativo: false,
        //   icone: "flaticon-auction",
        // },
        {
          nome: 'Lotes Arrematados',
          link: 'lotesarrematados',
          ativo: this.ativo == 'lotesarrematados',
          icone: 'flaticon-best-seller',
        },
        // {
        //   nome: "Agendamento",
        //   link: "agendamento",
        //   ativo: this.ativo == "agendamento",
        //   icone: "flaticon-alarm",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(['getAuth', 'getFotoUsuarioLogado']),
    ativo() {
      return this.$route.name;
    },
  },
  methods: {
    setAtivo(link) {
      this.itens = this.itens.map((e) => {
        return { ...e, ativo: e.link == link };
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
@media (max-width: 576px) {
  .dashboard-section {
    padding-top: 180px;
  }

  .sticky-menu {
    position: relative;
    top: 0;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
