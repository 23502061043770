<template>
  <div>
    <section class="account-section padding-bottom padding-top">
      <div class="container">
        <div class="account-wrapper">
          <div class="left-side">
            <div class="section-header">
              <h2 class="title">Login</h2>
              <p>Escolha como deseja entrar em sua conta.</p>
            </div>
            <ul class="login-with">
              <li>
                <a
                  href="#"
                  @click="loginGoogleFacebook('Facebook')"
                  class="facebook"
                  ><i class="fab fa-facebook"></i>Entrar com Facebook</a
                >
              </li>
              <li>
                <a
                  href="#"
                  @click="loginGoogleFacebook('Google')"
                  class="google"
                  ><i class="fab fa-google-plus"></i>Entrar com Google</a
                >
              </li>
            </ul>
            <div class="or">
              <span>Ou</span>
            </div>
            <form class="login-form" @submit.prevent="logarUsuarioSenha">
              <div class="form-group mb-30">
                <label for="login-email"><i class="far fa-envelope"></i></label>
                <input
                  type="text"
                  id="login-email"
                  placeholder="Email"
                  required
                  v-model="nomeUsuario"
                />
              </div>
              <div class="form-group">
                <label for="login-pass"><i class="fas fa-lock"></i></label>
                <input
                  type="password"
                  id="login-pass"
                  placeholder="Senha"
                  v-model="senha"
                />
                <span class="pass-type"><i class="fas fa-eye"></i></span>
              </div>
              <div class="alert alert-danger" v-if="getMensagem">
                {{ getMensagem }}
              </div>
              <div class="alert alert-danger" v-if="getLoginError">
                {{ getLoginError }}
              </div>
              <div class="alert alert-danger" v-if="inativo">
                Conta desativada
              </div>
              <div class="form-group">
                <a href="javascript:void()" @click="esqueceuSuaSenha()"
                  >Esqueceu sua senha?</a
                >
              </div>
              <div class="form-group">
                <a href="javascript:void()" @click="reenviarConfirmacao()"
                  >Reenviar confirmação de email</a
                >
              </div>
              <div class="form-group mb-0">
                <button
                  type="submit"
                  class="custom-button login-button"
                  :disabled="getLoginLoading"
                >
                  <i class="fa fa-spin fa-spinner" v-if="getLoginLoading"></i>
                  Entrar
                </button>
              </div>
              <!--<div class="form-group mb-0 pt-3">
                <a href="javascript:void(0)" @click="logout" class="btn btn-link">Sair</a>
              </div>-->
            </form>
          </div>
          <div class="right-side cl-white">
            <div class="section-header mb-0">
              <h3 class="title mt-0">Novo por aqui?</h3>
              <p>Crie sua conta grátis</p>
              <a href="/cadastro" class="custom-button cadastre-button"
                >Cadastre-se</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import notification from '@/mixins/notification';
import { ID_URL } from '../../config/config';
export default {
  data() {
    return {
      nomeUsuario: '',
      senha: '',
      provedor: '',
      inativo: false,
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  mixins: [notification],
  computed: {
    ...mapGetters([
      'getMensagem',
      'getLoginError',
      'getLoginLoading',
      'getAuth',
      'getRegisterCompleted',
    ]),
  },
  methods: {
    ...mapActions([
      'setLoginGoogle',
      'setLoginFacebook',
      'setLogin',
      'logout',
      'checkRegisterCompleted',
    ]),
    async logarUsuarioSenha() {
      const payload = {
        nomeUsuario: this.nomeUsuario,
        senha: this.senha,
        returnUrl: this.$route.query.returnUrl,
      };
      await this.setLogin(payload);

      const rc = await this.checkRegisterCompleted();

      if (!rc.ativo) {
        this.$store.commit('SET_AUTH');
        this.inativo = true;
      } else {
        this.$router.push(this.$route.query.returnUrl || { name: 'index' });
      }
    },
    async loginGoogleFacebook(provider) {
      if (provider === 'Google') {
        await this.setLoginGoogle();
      } else if (provider === 'Facebook') {
        await this.setLoginFacebook();
      }

      const rc = await this.checkRegisterCompleted();

      if (!rc.cadastroCompleto) {
        this.$router.push({ name: 'cadastro' });
      } else {
        this.$router.push(this.$route.query.returnUrl || { name: 'index' });
      }

      if (!rc.ativo && rc.cadastroCompleto) {
        this.$store.commit('SET_AUTH');
        this.inativo = true;
        return;
      }
    },

    esqueceuSuaSenha() {
      const url = ID_URL + 'identity/account/ForgotPassword';
      const newwindow = window.open(url, 'popup', 'width=450,height=480');
      if (window.focus) {
        newwindow.focus();
      }
      return false;
    },
    async reenviarConfirmacao() {
      this.$router.push({ name: 'reenviar-confirmacao-email' });
      return false;
    },
  },
  async created() {
    // if ("inativo" in this.$route.params) {
    //   this.inativo = true;
    // }

    if ('emailConfirmado' in this.$route.query) {
      this.showAlert({
        type: 'success',
        title: 'Obrigado!',
        message:
          'Seu email foi confirmado com sucesso, faça o login agora mesmo.',
      });
    }
  },
};
</script>

<style scoped>
.account-wrapper .right-side,
.login-button {
  background-color: v-bind(colorPrimary) !important;
  color: v-bind(colorConstrast) !important;
  font-weight: 500 !important;
}

.cadastre-button {
  background-color: v-bind(colorConstrast);
  color: v-bind(colorPrimary);
}

@media (max-width: 576px) {
  .account-section {
    padding-top: 180px;
  }
}
</style>
