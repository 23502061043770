/* eslint-disable */
// import axios from "axios"
import http from '../../service/http/http';

const state = {
  paginas: [],
  paginasLoading: {
    carregando: false,
  },
};

const mutations = {
  SET_PAGINAS: (state, value) =>
    value ? (state.paginas = value) : (state.paginas = []),
  SET_PAGINAS_LOADING: (state, value) =>
    value
      ? (state.paginasLoading.carregando = value)
      : (state.paginasLoading.carregando = false),
};

const getters = {
  getPaginas(state) {
    return state.paginas;
  },
  getPaginasLoading(state) {
    return state.paginasLoading.carregando;
  },
};

const actions = {
  async setPaginas({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit('SET_PAGINAS_LOADING', true);
      return http({
        url: `PaginaEstatica`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_PAGINAS', resp.data.data);
          commit('SET_PAGINAS_LOADING');
          resolve();
        })
        .catch(() => {
          commit('SET_PAGINAS_LOADING');
          reject();
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
