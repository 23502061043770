<template>
  <section class="content" v-if="banners">
    <carousel
      :perPage="1"
      :autoplay="true"
      :loop="true"
      :speed="2000"
      :autoplayHoverPause="true"
      :autoplayTimeout="6000"
      :mouse-drag="true"
      paginationPosition="bottom-overlay"
      paginationColor="transparent"
      :paginationActiveColor="colorSecondary"
      class="banner-home"
    >
      <slide
        class="slide"
        v-for="banner in banners"
        :key="banner.bannerHomerId"
      >
        <a :href="banner.linkUrl"
          ><img class="img" :src="banner.imagemUrl"
        /></a>
      </slide>
    </carousel>
  </section>
</template>

<script>
import http from '@/service/http/http';
import { Carousel, Slide } from 'vue-carousel';
export default {
  data() {
    return {
      banners: [],
      windowWidth: window.innerWidth,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
    };
  },
  components: {
    Carousel,
    Slide,
  },
  computed: {
    isMobile() {
      return this.windowWidth < 576;
    },
  },
  methods: {
    getBanner() {
      return http({
        url: `CMS/Banner`,
        method: 'get',
      });
    },
  },
  mounted() {
    this.getBanner().then(
      (item) =>
        (this.banners = item.data.data.filter((x) => x.mobile == this.isMobile))
    );
  },
};
</script>

<style>
.VueCarousel-dot-container {
  margin-top: 0;
}
.VueCarousel-wrapper {
  text-align: center;
}

.VueCarousel-dot-container {
  padding: 0;
}

.VueCarousel-dot {
  padding: 0 !important;
  margin: 20px 10px !important;
  border: 1px solid #fff !important;
}

.VueCarousel-dot--active {
  border: 1px solid v-bind(colorSecondary) !important;
}

.img {
  width: 100%;
}

@media (max-width: 576px) {
  .VueCarousel-dot {
    border: 1px solid #ccc !important;
  }

  .VueCarousel-dot--active {
    border: 1px solid v-bind(colorSecondary) !important;
  }
}
</style>
