<template>
  <div :class="classeRaiz" class="box-leilao">
    <div class="auction-item-2">
      <div class="auction-thumb border-radius-5 thumb-height-247">
        <router-link :to="'/lotes/' + leilao.id"><img :src="leilao.foto.url" :title="leilao.nome" /></router-link>
      </div>
      <div class="field-badge">
        <span class="badge badge-gray bg-secondary w-100" v-if="leilao.tipo == 'JUDICIAL'">JUDICIAL</span>
        <span class="badge badge-yellow bg-secondary w-100" v-if="leilao.tipo == 'EXTRAJUDICIAL'">EXTRAJUDICIAL</span>
        <span class="badge badge-orange bg-secondary w-100" v-if="leilao.tipo == 'VENDA DIRETA'">VENDA DIRETA</span>
      </div>
      <div class="auction-content">
        <div class="title-adress text-center">
          <h6 class="title mb-1 px-2 w-100 text-truncate">
            {{ leilao.nome }}
          </h6>
          <p class="titulo-leilao">{{ leilao.titulo }}</p>
        </div>
      </div>
    </div>
    <div class="information">
      <div class="text" v-if="leilao.pracas.length > 0">
        <ul>
          <li
            v-for="praca in leilao.pracas"
            :key="praca.pracaLeilaoId"
            class="pb-2"
            :class="{
              'leilao-aberto': isPracaAtual(praca),
              'leilao-fechado': praca.status == 2,
            }"
          >
            <strong>{{ praca.numeroPraca }}ª LEILÃO: </strong>{{ praca.dataExecucao | formatDataHora }}
            <br />
            <span v-if="leilao.qtdLotes == 1"
              ><strong>LANCE INICIAL: </strong
              >{{
                praca.lanceInicial
                  | currency('R$', 2, {
                    spaceBetweenAmountAndSymbol: true,
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                  })
              }}</span
            >
          </li>
        </ul>
      </div>
      <div v-else>
        <ul>
          <!-- <li><strong>{{ leilao.pracaAtual.numeroPraca }}ª Praça: </strong>03/08/2023 - 15H00<br><strong>ONLINE E PRESENCIAL</strong></li> -->
          <li><strong>DATA: </strong>{{ leilao.dataLeilao | formatDataHora }}</li>
          <li><strong>CATEGORIA: </strong>{{ leilao.categoria }}</li>
          <li><strong>Nº LOTES: </strong>{{ leilao.qtdLotes }}</li>
        </ul>
      </div>
    </div>
    <div class="detail-button">
      <router-link
        :to="'/lotes/' + leilao.id"
        class="custom-button detail"
        :class="{ 'box-pulse-yellow': leilao.statusSite === 'aberto' && (leilao.status === 'Em Andamento' || leilao.status === 'Em Pregão') }"
      >
        <span v-if="leilao.statusSite === 'aberto' && (leilao.status === 'Aberto' || leilao.status === 'Agendado')">ABERTO</span>
        <span v-else-if="leilao.statusSite === 'aberto' && (leilao.status === 'Em Andamento' || leilao.status === 'Em Pregão')">EM PREGÃO</span>
        <span v-else-if="leilao.statusSite === 'fechado' && leilao.status === 'Cancelado'">CANCELADO</span>
        <span v-else-if="leilao.statusSite === 'fechado' || leilao.status === 'Encerrado'">ENCERRADO</span>
        <span v-else>EM BREVE</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
moment.locale('pt-br');
export default {
  props: {
    classeRaiz: {
      type: String,
    },
    leilao: {
      type: Object,
      required: true,
    },
    isAPageLeiloes: Boolean,
  },
  data() {
    return {
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  methods: {
    isPracaAtual(praca) {
      return this.leilao.pracaAtual.pracaLeilaoId == praca.pracaLeilaoId;
    },
  },
  filters: {
    formatDataHora(value) {
      return moment.utc(value).local().format('DD/MM/YYYY [às] HH:mm');
    },
  },
};
</script>

<style scoped>
.leilao-fechado {
  text-decoration: line-through;
}

.leilao-aberto {
  color: #52606d !important;
}

.titulo-leilao {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0px 10px;
  font-size: 12px;
  margin: 0;
}

.box-leilao {
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.title-adress {
  display: flex;
  align-items: center;
  text-align: center;
  height: 120px;
  background-color: v-bind(colorSecondary);
  border-radius: 0px 0px 20px 20px;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
}
.title-adress,
h6 {
  color: white;
}
.title-adress,
p {
  color: white;
}

.field-badge {
  width: 100%;
  position: absolute;
  top: 187px;
}
.fieldBadgeisAPageLeiloes {
  width: 270px !important;
  position: absolute;
}

.badge {
  display: flex;
  border-radius: 15px;
  height: 35px;
  font-size: 100%;
  justify-content: center;
  align-items: center;
}

.badge-gray {
  background-color: #d9d9d9 !important;
}

.badge-yellow {
  background-color: #fdff83 !important;
}

.badge-orange {
  background-color: #f1d8a9 !important;
}

.icone-lance {
  width: 40px;
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  font-size: 18px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.13);
}

.icone-lance-aberto {
  background-color: #2baf49;
}

.text-lance {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}

.information {
  margin: 0 auto 0px;
  border: 1px solid rgb(218, 218, 218);
  border-top: 0px;
  border-bottom: 0;
  text-align: left;
  flex: 1 1;
  padding: 20px 15px 60px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-button {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  left: 0;
  padding: 0 15px;
  bottom: 0px;
}

.auction-item-2 .auction-thumb {
  height: 200px;
  max-height: 200px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  position: relative;
}

.auction-thumb a {
  width: 100%;
  height: 100%;
}

.auction-thumb a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.text-lance-aberto {
  color: #2baf49;
}

.text {
  font-size: 12px;
  color: #52606da1 !important;
}

.text ul li {
  padding: 0;
}
.detail {
  width: 100% !important;
  text-align: center;
  background-color: v-bind(colorPrimary);
  color: v-bind(colorConstrast);
}
@media (min-width: 1140px) {
  .fieldBadgeisAPageLeiloes {
    width: 270px !important;
    position: absolute;
  }
}

.box-pulse-yellow {
  border: 2px solid #f9c718;
  box-shadow: 0 0 0 0 rgba(249, 199, 24, 1);
  animation: pulse-yellow 1.5s infinite;
  background-color: #f7d663;
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(249, 199, 24, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0);
  }
}
</style>
